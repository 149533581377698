/**
 * 动销动耗报表
 */
 import * as API from '@/api/index'

 export default {
    // 动销动耗
    dynamicSaleAndDynamicConsume: params => {
        return API.POST('api/customerStatement/dynamicSaleAndDynamicConsume', params)
     },
    // 动销动耗导出
    dynamicSaleAndDynamicConsumeExcel: params => {
        return API.exportExcel('api/customerStatement/dynamicSaleAndDynamicConsumeExcel', params)
     },

 }